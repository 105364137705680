import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './plugins/element.js'
import store from './stores/store'
import infiniteScroll from "vue-infinite-scroll"



import dataV from '@jiaminghi/data-view';

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

Vue.component('icon', Icon);
Vue.use(dataV);

import router from './router'

Vue.config.productionTip = false
Vue.use(infiniteScroll)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')


