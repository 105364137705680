import Vue from "vue";
import vuex from "vuex";

Vue.use(vuex)

function deleteCookie(name) {
  const expires = 'Thu, 01 Jan 1970 00:00:01 GMT'; // 或 new Date(0).toUTCString()
  document.cookie = `${name}=; expires=${expires}; path=/; domain=${document.domain}`;
}
/**
 * store状态管理，
 */
const store = new vuex.Store({
  state() {
    return {
      isMobile: false,//是否为移动端登录
      showLog: false,//是否展示登录界面
      isLogin: false, //登录页标识
      Logged: false,//是否已登录
      dialogFormVisiblePic: false, //ppt主题修改页面标识
      dialogFormVisible: false,
      dialogFormVisibleTemplate: false, //策划案模板控制表单标识
      usrInfo: {}, //用户全局属性
    }
  },
  mutations: {
    setUsrInfo(state, usrInfo) {
      if (usrInfo.hasOwnProperty('usrName')) {
        state.usrInfo.usrName = usrInfo.usrName;
      }
      if (usrInfo.hasOwnProperty('headerImg')) {
        state.usrInfo.headerImg = usrInfo.headerImg;
      }
      if (usrInfo.hasOwnProperty('usrId')) {
        state.usrInfo.usrId = usrInfo.usrId;
      }
    },
    toggleMoblie(state) {
      state.isMobile = true
    },
    togglePC(state) {
      state.isMobile = false
    },
    toggleUnshowLog(state) {
      state.showLog = false
    },
    toggleShowLog(state) {
      state.showLog = true
    },
    adminLog(state) {
      state.isLogin = true
    },
    toggleLEView(state) {
      state.isLogin = !state.isLogin
    },
    //直接显示登录页
    openLog(state) {
      state.showLog = true
      state.isLogin = true
    },
    //直接显示注册
    openEnroll(state) {
      state.showLog = true
      state.isLogin = false
    },
    login(state) {
      state.Logged = true;
    },
    logOut(state) {
      document.cookie = 'token=; Max-Age=-99999999;';;
      document.cookie ='usr =; Max-Age=-99999999;';;
      state.Logged = false
      state.usrHeaderImg = 'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'
    },
    //直接显示ppt主题编辑
    openUpdatePPT(state) {
      state.dialogFormVisiblePic = true
    },
    closeUpdatePPT(state) {
      state.dialogFormVisiblePic = false
    },
    openAddPPT(state) {
      state.dialogFormVisible = true
    },
    closeAddPPT(state) {
      state.dialogFormVisible = false
    },
    openAddTemplate(state) {
      state.dialogFormVisibleTemplate = true
    },
    closeAddTemplate(state) {
      state.dialogFormVisibleTemplate = false
    }
  }
})



export default store