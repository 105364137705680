import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/stores/store';

import mainPage from '../pages/main.vue'

Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        component: mainPage,
        children: [
            {
                path: '/',
                components: {
                    mainBar: () => import('../pages/recommend.vue'),
                }
            },
            {
                path: '/recommend',
                components: {
                    mainBar: () => import('../pages/recommend.vue')
                }
            },
            {
                path: '/template',
                components: {
                    mainBar: () => import('../pages/main-page.vue')
                }
            },
            {
                path: '/MySpace',
                components: {
                    mainBar: () => import('../pages/MySpace.vue')
                },
                meta: {
                    requiresCookie: true,
                },
            }
        ]
    },
    {
        path: '/AI',
        component: () => import('../pages/ai-page.vue'),
        meta: {
            requiresCookie: true,
        },
    },
    {
        path: '/demand',
        component: () => import('../pages/demand-usr.vue')
    },
    {
        path: '/companiesList',
        component: () => import('../pages/companies-list-page.vue')
    },
    {
        path: '/admin',
        component: () => import('../admin-page/view/index.vue'),
        meta: {
            requiresCookie: true,
        },
        children: [
            //定义默认页面
            {
                path: '/',
                components: {
                    admin_page: ()=> import('../admin-page/WebsiteAdministration/index')
                }
            },
            {
                path: '/admin/bigScreen',
                components: {
                    admin_page:()=> import('../admin-page/WebsiteAdministration/index')
                }
            },
            {
                path:'/admin/manage',
                components:{admin_page:()=>import('../admin-page/view/management.vue')},
                children:[
                    {
                        path:'/admin/manage/usr',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/user/usr.vue')
                        }
                    },
                    {
                        path:'/admin/manage/commodity',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/commodity/commodity.vue')
                        }
                    },
                    {
                        path:'/admin/manage/option',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/option.vue')
                        }
                    },{
                        path:'/admin/manage/package',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/package/usr-package.vue')
                        }
                    },
                    
                    {
                        path:'/admin/manage/classes',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/classes/classes.vue')
                        }
                    },
                    
                    {
                        path:'/admin/manage/userop',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/ops/user-log.vue')
                        }
                    },{
                        path:'/admin/manage/adminop',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/ops/admin-log.vue')
                        }
                    },{
                        path:'/admin/manage/onlineusr',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/ops/online-usr.vue')
                        }
                    },
                    
                    
                    {
                        path:'/admin/manage/role',
                        components:{
                            manage:()=>import('../admin-page/management/compoents/role/role.vue')
                        }
                    },
                    {
                        path: '/admin/manage/theme',
                        components: {
                            manage: () => import('../components/ThemePPT/themePowerPoint.vue')
                        }
                    },
                    {
                        path: '/admin/manage/template',
                        components: {
                            manage: () => import('../components/ThemePPT/template.vue')
                        }
                    },
                    {
                        path: '/admin/manage/supplier',
                        components: {
                            manage: () => import('../components/ThemePPT/supplier.vue')
                        }
                    },
                ]
            }
        ]
    },
    {
        path:'/business',
        component: ()=>import('../business-page/view/index.vue'),
        meta: {
            requiresCookie: true,
        },
        children:[
            {
                path:'/',
                components: {
                    business:()=>import('../business-page/bigscreen/view/index.vue')
                }
            }
        ]
    },
    {
        name: 't',
        path: '/t',
        component: () => import('../pages/demand-translate.vue'),
    },
    {
        path: '/ddd',
        component: () => import('../pages/desi.vue')
    },
    {
        path: '/mobile',
        component: () => import('../mobile/page/ai-page.vue')
    },
    {
        path: '/APIKEYSET',
        component: () => import('../utils/KEY.vue')
    },
]


const router = new VueRouter({
    routes,
    mode: 'history'
})
router.beforeEach((to, from, next) => {
    try {
        if (to.meta.requiresCookie) {
            const hasRequiredCookie = document.cookie.split(';').some(cookie => cookie.trim().startsWith(`token=`))
            if (hasRequiredCookie) {
                next();
            } else {
                store.commit('openLog');
                next('/')
            }
        } else {
            next();
        }
    } catch (error) {
        console.log(error)
    }
    // if (document.documentElement.clientWidth < 600 && to.path !== '/mobile') {
    //     next('/mobile');
    // } else {
    //     next();
    // }
});

export default router