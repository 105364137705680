<template>
    <el-container style="height: 97vh;">
        <el-header height="40px" class="el-header">
            <el-row>
                <el-col :span="8">
                    <button class="favicon-header"><img :src="logoPath" alt=""></button>
                </el-col>

                <el-col :span="8">
                    <div class="search_box">
                        <input class="search" v-model="searchStr" @input="querySerchSuggestion" type="text">
                        <div class="submit_query">搜索</div>
                    </div>
                </el-col>

                <el-col :span="8">
                    <div class="rightH">
                        <el-dropdown>
                            <div class="demand">
                                <i class="el-icon-upload"></i>
                                发布
                            </div>
                            <!-- 发布需求 -->
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus">方案</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus">ppt</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-circle-plus-outline">海报</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-check">需求</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <div class="l_enBox" v-if="this.$store.state.Logged">
                            <el-dropdown @command="logout">
                                <div class="small-header-img"
                                    :style="{ backgroundImage: 'url(' + this.$store.state.usrInfo.headerImg + ')' }">
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-close">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                        </div>
                        <div class="l_enBox" v-else>
                            <button class="login_but" @click="openLog">登录</button>
                            <button class="enroll_but" @click="openEnroll">注册</button>
                        </div>

                    </div>
                </el-col>
            </el-row>
        </el-header>
        <el-container>
            <el-aside width="250px" style="margin-top: 20px;">
                <el-row class="tac" style="width: auto">
                    <el-col>

                        <el-menu default-active="1" class="el-menu-vertical-demo" @select="handleSelect"
                            @open="handleOpen" @close="handleClose" router>
                            <el-menu-item-group>
                                <el-menu-item index="recommend"><svg role="img" aria-label="compass" focusable="false"
                                        data-icon="compass" aria-hidden="true" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" class="gdesign-icon gdesign-icon-compass">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.4189 7.52705L16.0513 7.31623C16.4422 7.18593 16.8141 7.5578 16.6838 7.94868L16.473 8.58114L16.4098 8.77073L16.4071 8.77878L14.5 14.5L8.77878 16.4071L8.77073 16.4098L8.58114 16.473L7.94868 16.6838C7.5578 16.8141 7.18593 16.4422 7.31623 16.0513L7.52705 15.4189L7.59024 15.2293L7.59293 15.2212L9.5 9.5L15.2212 7.59293L15.2293 7.59024L15.4189 7.52705ZM10.6859 10.6859L9.37171 14.6283L13.3141 13.3141L14.6283 9.37171L10.6859 10.6859Z"
                                            fill="currentColor"></path>
                                    </svg>首页</el-menu-item>
                                <el-menu-item index="template"><svg role="img" aria-label="template" focusable="false"
                                        data-icon="template" aria-hidden="true" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" class="gdesign-icon gdesign-icon-template">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M5 4.5C4.72386 4.5 4.5 4.72386 4.5 5V19C4.5 19.2761 4.72386 19.5 5 19.5H14.2C14.4761 19.5 14.7 19.2761 14.7 19V5C14.7 4.72386 14.4761 4.5 14.2 4.5H5ZM3 5C3 3.89543 3.89543 3 5 3H14.2C15.3046 3 16.2 3.89543 16.2 5V19C16.2 19.1726 16.1781 19.3401 16.137 19.4998C16.1781 19.3401 16.2 19.1726 16.2 19V18.5863L18.5694 19.0041C18.8413 19.052 19.1007 18.8704 19.1486 18.5985L20.9255 8.52153C20.9734 8.24958 20.7918 7.99025 20.5199 7.9423L16.2 7.18059V5.65745L20.7804 6.46509C21.8681 6.65689 22.5945 7.69421 22.4027 8.782L20.6258 18.8589C20.434 19.9467 19.3967 20.6731 18.3089 20.4813L15.8986 20.0563C15.8986 20.0563 15.8986 20.0563 15.8987 20.0562C15.5456 20.6229 14.9168 21 14.2 21H5C3.89543 21 3 20.1046 3 19V5Z"
                                            fill="currentColor"></path>
                                    </svg>智境模板</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group title="分组2">
                                <template slot="title">工作台</template>
                                <el-menu-item index="1-3" disabled><svg role="img" aria-label="recent" focusable="false"
                                        data-icon="recent" aria-hidden="true" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" class="gdesign-icon gdesign-icon-recent">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20.4962 12C20.4962 7.30766 16.6923 3.50376 12 3.50376C7.30766 3.50376 3.50376 7.30766 3.50376 12C3.50376 16.6923 7.30766 20.4962 12 20.4962C16.6923 20.4962 20.4962 16.6923 20.4962 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.6875 12.4717L16.4399 15.4736L15.5809 16.5473L11.3125 13.1325V7.1875H12.6875V12.4717Z"
                                            fill="currentColor"></path>
                                    </svg>最近打开</el-menu-item>
                                <el-menu-item index="MySpace"><svg role="img" aria-label="user-circle" focusable="false"
                                        data-icon="user-circle" aria-hidden="true" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="gdesign-icon gdesign-icon-user-circle">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.9995 20.5012C14.2223 20.5012 16.2457 19.648 17.7603 18.2513C16.2446 16.853 14.2217 16.0012 11.9992 16.0012C9.77682 16.0012 7.75409 16.8528 6.23835 18.2509C7.75298 19.6479 9.7766 20.5012 11.9995 20.5012ZM11.9992 14.5012C14.6107 14.5012 16.9885 15.5023 18.7696 17.1416C19.8551 15.7141 20.4995 13.9329 20.4995 12.0012C20.4995 7.30674 16.6939 3.50116 11.9995 3.50116C7.30509 3.50116 3.49951 7.30674 3.49951 12.0012C3.49951 13.9327 4.1438 15.7138 5.22918 17.1412C7.01024 15.5021 9.38781 14.5012 11.9992 14.5012ZM21.9995 12.0012C21.9995 14.3661 21.1785 16.5393 19.806 18.2514L19.8062 18.2516C17.9735 20.5379 15.1573 22.0019 11.9992 22.0019C8.841 22.0019 6.02478 20.5379 4.19214 18.2516L4.19263 18.2509C2.82034 16.539 1.99951 14.3659 1.99951 12.0012C1.99951 6.47831 6.47666 2.00116 11.9995 2.00116C17.5224 2.00116 21.9995 6.47831 21.9995 12.0012ZM14 9.50195C14 10.6065 13.1046 11.502 12 11.502C10.8954 11.502 10 10.6065 10 9.50195C10 8.39738 10.8954 7.50195 12 7.50195C13.1046 7.50195 14 8.39738 14 9.50195ZM15.5 9.50195C15.5 11.4349 13.933 13.002 12 13.002C10.067 13.002 8.5 11.4349 8.5 9.50195C8.5 7.56896 10.067 6.00195 12 6.00195C13.933 6.00195 15.5 7.56896 15.5 9.50195Z"
                                            fill="currentColor"></path>
                                    </svg>我的空间</el-menu-item>
                            </el-menu-item-group>
                            <el-menu-item-group>
                                <template slot="title">创建设计</template>
                                <el-menu-item index="AI"><svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" t="1709452146975" class="icon"
                                        viewBox="0 0 1024 1024" version="1.1" p-id="7007">
                                        <path
                                            d="M517.5 953.2H239.6c-13.8 0-25-11.2-25-25s11.2-25 25-25h277.9c13.8 0 25 11.2 25 25s-11.2 25-25 25z"
                                            fill="#2c2c2c" p-id="7008" />
                                        <path
                                            d="M517.5 952.1s-0.1 0 0 0c-9.9 0-18.9-5.6-23.3-14.7L354.4 656.7V106.3c0-19.8 16.1-35.8 35.8-35.8h256.1c19.8 0 35.8 16.1 35.8 35.8v550.5L540.8 937.5c-4.5 9-13.5 14.6-23.3 14.6zM404.4 645l113.2 227.4 114.6-227.5V120.4H404.4V645z"
                                            fill="#2c2c2c" p-id="7009" />
                                        <path d="M378.6 625.8h277.8v50H378.6z" fill="#2c2c2c" p-id="7010" />
                                    </svg>AI策划</el-menu-item>
                                <!-- <el-menu-item index="1-6">选项2</el-menu-item> -->
                            </el-menu-item-group>
                            <el-menu-item-group>
                                <template slot="title">商务合作</template>
                                <el-menu-item index="companiesList"><svg t="1711175857748" class="icon"
                                        viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        p-id="47127" width="18" height="18">
                                        <path
                                            d="M795.591194 63.739684 226.198359 63.739684 63.510452 343.589491c0 0 0 95.998913 0 111.940741 3.323858 28.184228 15.217293 56.068613 32.532465 77.631732l0 321.099211c0 61.817823 48.02504 106.019608 111.790308 106.019608l606.125149 0c63.766291 0 102.096063-44.200762 102.096063-106.019608L916.054438 533.161964c19.59009-23.062335 31.508086-40.402067 43.150799-77.631732 0-30.63414 0-111.940741 0-111.940741L795.591194 63.739684zM247.110624 106.416669l517.872013 0 129.480028 234.721885L117.629573 341.138555 247.110624 106.416669zM808.15902 918.254652 203.935265 918.254652c-31.883657 0-64.742572-33.08303-64.742572-63.990406l0.001023 0 0-65.038322 735.729003 0 0.299843 59.116165C875.224608 879.275049 840.01914 918.254652 808.15902 918.254652zM874.923742 746.546891 139.195763 746.546891l-0.001023 0 0.001023 0L139.195763 554.500968c17.865737 14.76804 50.024676 21.362542 64.742572 21.362542 30.082552 0 76.258389-35.206492 96.423605-76.208245 22.637642 39.878109 57.441956 76.208245 97.796948 76.208245 42.651402 0 97.046828-33.780958 112.736912-70.386377 15.69213 35.756034 61.267258 70.386377 103.044714 70.386377 41.427469 0 84.70414-38.828146 106.416669-80.432655 25.388421 42.003618 56.370503 80.432655 109.367003 80.432655 8.197076 0 38.355356-7.046825 45.499399-8.395607L874.923742 746.546891zM813.76803 508.146044c-45.000002-0.023537-97.420353-34.982377-97.420353-63.666002l-21.59075 0c0 28.207765-43.100655 63.666002-86.251454 63.666002-41.380394 0-86.355836-36.330136-86.355836-63.666002l-21.588703 0c0 28.207765-57.670164 63.666002-99.073072 63.666002-43.150799 0-95.149528-27.585565-95.149528-63.666002l-21.563119 0c0 28.134084-45.449255 64.538924-86.926868 64.538924-42.103907 0-85.703959-25.909309-85.703959-64.538924L112.144389 382.508716l798.420771 0 0 61.971326 0.002047 0C910.169121 483.109657 852.773217 508.169581 813.76803 508.146044z"
                                            fill="#2c2c2c" p-id="47128"></path>
                                    </svg>供应商</el-menu-item>
                                <el-menu-item index="demand"><svg t="1711175737034" class="icon" viewBox="0 0 1024 1024"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="45956" width="18"
                                        height="18">
                                        <path
                                            d="M1024 608c0 229.76-229.248 416-512 416S0 837.76 0 608c0-157.696 140-294.848 299.168-365.408L160 64c0-35.328 28.672-64 64-64l576 0c35.328 0 64 28.672 64 64l-139.168 178.592C884 313.152 1024 450.304 1024 608zM629.728 276.576 768 64 256 64l138.272 212.576C220.608 325.024 64 454.912 64 608c0 194.4 200.576 352 448 352s448-157.6 448-352C960 454.912 803.392 325.024 629.728 276.576zM544 800l-64 0 0-96L288 704l0-32 192 0 0-64L288 608l0-32 128 0 0-32-32 0 0-32-32 0 0-32-32 0 0-32 96 0 0 32 32 0 0 32 32 0 0 32 64 0 0-32 32 0 0-32 32 0 0-32 96 0 0 32-32 0 0 32-32 0 0 32-32 0 0 32 128 0 0 32-192 0 0 64 192 0 0 32-192 0L544 800z"
                                            fill="#040000" p-id="45957"></path>
                                    </svg>需求悬赏</el-menu-item>
                            </el-menu-item-group>
                        </el-menu>
                    </el-col>
                </el-row>

                <div class="side-dowm-box">
                    <div class="identity">
                        <svg t="1711178702948" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="48358" width="24" height="24">
                            <path
                                d="M944 493.726c0 22.018-10.64 62.57-81.906 86.78C825.086 593.08 776.042 600 724 600c-13 0-25.832-0.432-38.326-1.278a185.93 185.93 0 0 0 14.312-71.188c7.658 0.358 15.65 0.56 24.014 0.56 94.284 0 143.764-24.574 148-34.586-0.128-73.234-65.66-132.836-146.492-133.574-0.838 0.016-1.668 0.064-2.508 0.064-0.522 0-1.038-0.034-1.558-0.04a157.58 157.58 0 0 0-85.486 26.326 186.882 186.882 0 0 0-67.498-37.884 220.584 220.584 0 0 1 49.854-35.056 141.012 141.012 0 1 1 210-0.712C897.136 347.436 944 415.558 944 493.726zM723 150a69 69 0 1 0 69 69 69.078 69.078 0 0 0-69-69z m-69 377a140.4 140.4 0 0 1-35.696 93.628C687.136 655.436 734 723.558 734 801.726c0 22.018-10.64 62.57-81.906 86.78C615.086 901.08 566.042 908 514 908s-101.088-6.922-138.094-19.496C304.64 864.296 294 823.744 294 801.726c0-77.642 46.238-145.37 114.312-180.386A140.968 140.968 0 1 1 654 527zM366 801.51c4.236 10.012 53.716 34.586 148 34.586s143.764-24.574 148-34.586c-0.128-73.234-65.66-132.836-146.492-133.574-0.838 0.016-1.668 0.064-2.508 0.064-0.522 0-1.038-0.034-1.558-0.04C431.094 669.2 366.128 728.6 366 801.51zM513 458a69 69 0 1 0 69 69 69.08 69.08 0 0 0-69-69z m-88.45-95.712a188.322 188.322 0 0 0-58.772 49.616 159.866 159.866 0 0 0-64.27-13.968c-0.838 0.016-1.668 0.064-2.508 0.064-0.522 0-1.038-0.034-1.558-0.04C217.094 399.2 152.128 458.6 152 531.51c4.236 10.012 53.716 34.586 148 34.586 10.552 0 20.51-0.322 29.938-0.882a186.068 186.068 0 0 0 30.372 69.54A551.616 551.616 0 0 1 300 638c-52.044 0-101.086-6.922-138.094-19.496C90.64 594.296 80 553.744 80 531.726c0-77.642 46.238-145.37 114.312-180.386a141.012 141.012 0 1 1 210-0.712 223.62 223.62 0 0 1 20.238 11.66zM299 188a69 69 0 1 0 69 69 69.08 69.08 0 0 0-69-69z"
                                p-id="48359"></path>
                        </svg>
                        <div class="text">团队协作</div>
                    </div>
                    <div class=""></div>
                </div>
            </el-aside>
            <el-main id="main"><router-view name="mainBar"></router-view></el-main>
        </el-container>
    </el-container>
</template>

<script>
import imgPath from '../utils/imgPath'
export default {
    data() {
        return {
            drawer: false,
            searchStr: '',
            logoPath: imgPath.logoPath,

        }
    },
    methods: {
        handleOpen(index, indexPath) {

            console.log('Menu opend:', index, indexPath);
        },
        handleClose(index, indexPath) {

            console.log('Menu closed:', index, indexPath);
        },
        handleSelect(index, indexPath) {

            console.log('Menu select', index, indexPath)
        },
        logout() {
            console.log(111)
            this.$store.commit('logOut')
            window.location.href = "/"
        },
        //跳转登录
        openLog() {
            this.$store.commit('openLog')
        },
        //跳转注册
        openEnroll() {
            this.$store.commit('openEnroll')
        },
        //获取搜索建议
        querySerchSuggestion() {
            console.log(this.searchStr);
        },
    },
    created() {

    },
    mounted() {

    },
    comments: {

    }
};
</script>


<style scoped lang="less">
.el-header {
    border-bottom: #0000002c 1px solid;
}

.el-menu-item {
    font-size: small;
    height: 4em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: calc(100% - 10px);

    svg {
        height: 1.5em;
        margin-right: 12px;
    }
}

/* 顶部logo样式 */
.favicon-header {
    width: auto;
    height: 40px;
    border: none;
    background: none;

    img {
        height: 100%;
    }
}

.el-aside {
    position: relative;

    .side-dowm-box {
        position: absolute;
        bottom: 20px;

        .identity {
            width: 230px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 2px solid #444040;
            cursor: pointer;
        }
    }
}

/* 搜索框样式 */
.search_box {
    display: flex;
    --height: 30px;
    --border-radiu: calc(var(--height) / 2);
    --border-size: 1px;
    --border-color: #4c9ff3;

    .search {
        height: var(--height);
        width: 100%;
        border: var(--border-size) solid var(--border-color);
        border-radius: var(--border-radiu) 0 0 var(--border-radiu);
        padding-left: 10px;
    }

    .submit_query {
        height: var(--height);
        border: var(--border-size) solid var(--border-color);
        border-radius: 0 var(--border-radiu) var(--border-radiu) 0;
        cursor: pointer;
        width: 90px;
        text-align: center;
        line-height: var(--height);
        color: #fff;
        background: var(--border-color);
    }
}

.rightH {
    display: flex;
    justify-content: flex-end;
}

/* 发布按钮样式 */
.demand {
    height: 30px;
    text-align: center;
    line-height: 30px;
    width: 70px;
}

.demand:hover {
    background: #EBEEF5;
}

/* 登录按钮样式 */
.l_enBox {
    height: 30px;
    min-width: 30px;

    button {
        border: 1px #409EFF solid;
        width: 60px;
        height: 30px;
        background: #fff;
    }

    button:hover {
        cursor: pointer
    }

    /* 登录按钮样式 */
    .login_but {
        color: #409EFF;
    }

    .login_but:hover {
        color: #5da9f6;
        background: #f7f7f7;
    }

    /* 注册按钮 */
    .enroll_but {
        background: #409EFF;
        color: #fff;
    }

    .enroll_but:hover {
        background: #5da9f6;
    }

    /* 右上角头像框样式 */
    .small-header-img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: #000 1px solid;
        background-size: auto 100%;
    }
}
</style>