<template>
  <div id="app">
    <RouterView></RouterView>
    <login v-if="this.$store.state.showLog"></login>
  </div>
</template>

<script>
function getCookie(name) {
    var nameEQ = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.startsWith(nameEQ)) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null; // 如果未找到对应的Cookie，返回null或空字符串
}

export default {
  name: 'app',
  data(){
    return {
      
    }
  },
  components: {
    login:()=>import('./pages/login.vue'),
  },
  created(){
    this.isMobile()
  },
  updated(){
    this.isMobile()
  },
  mounted(){
    this.logined()
  },
  methods: {
    /**
     * 判断是否未移动端访问，若为移动端访问则提交`toggleMoblie`
     * 若为PC端访问，则提交`togglePC`
     */
    isMobile() {
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      if(isMobile){
        this.$store.commit('toggleMoblie')
        console.log(this.$store.state.isMobile);
      }else{
        this.$store.commit('togglePC')
        console.log(this.$store.state.isMobile);
      }
      
    },
    logined(){
      
      // const tooken = document.cookie.split(';').some(cookie => cookie.trim().startsWith(`tooken=`))

      const token = getCookie('token');
      if(token){
        console.log("token",token);
        this.$store.commit('login');
        const usrInfo = getCookie('usr');
        if(usrInfo){
          this.$store.commit('setUsrInfo',JSON.parse(decodeURIComponent(escape(window.atob(usrInfo)))));
        }
      }else{
        console.log('no token')
        this.$store.commit('logOut')
      }
    }
  }
}
</script>

<style>
*{
  padding:0;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
